import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit"
import formsPageReducer from "../pages/formsPage/redux/formsPage.slice"
import voiceRecorderReducer from "../features/pressToSpeak/redux/voiceRecorder.slice"
import { commonSlice } from "../redux/common.slice"

export const store = configureStore({
  reducer: {
    common: commonSlice.reducer,
    formsPage: formsPageReducer,
    voiceRecorder: voiceRecorderReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
