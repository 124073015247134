import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/prism"
import React from "react"
import ClipboardJS from "clipboard"
import { Fab } from "@mui/material"
import CopyIcon from "@mui/icons-material/ContentCopy"

export interface ICodeSnippetProps {
  code: string
  id: string
  language: string
}

export const CodeSnippet: React.FC<ICodeSnippetProps> = ({
  code,
  id,
  language,
}) => {
  new ClipboardJS(".copy-button-" + id, {
    text: () => code,
  })

  return (
    <div style={{ position: "relative" }}>
      <SyntaxHighlighter language={language} style={a11yDark}>
        {code}
      </SyntaxHighlighter>
      <Fab
        className={"copy-button-" + id}
        sx={{
          position: "absolute",
          bottom: 16,
          right: 16,
        }}
        aria-label={"copy"}
        size="small"
      >
        <CopyIcon />
      </Fab>
    </div>
  )
}
