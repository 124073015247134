import React, { useEffect } from "react"
import "./home.scss"
import { Hero } from "./components/Hero"
import { Values } from "./components/Values"
import { Action } from "./components/Action"
import { GetStarted } from "./components/GetStarted"
import { getAuthUrl, getUserInfo, setToken } from "../../redux/common.slice"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectAuthUrl, selectToken } from "../../redux/common.selectors"
import { useNavigate } from "react-router-dom"
import { Api } from "./components/Api"
import { ContactUs } from "./components/ContactUs"

export function Home() {
  const dispatch = useAppDispatch()
  const authUrl = useAppSelector(selectAuthUrl)
  const navigate = useNavigate()
  const token = useAppSelector(selectToken)

  useEffect(() => {
    if (!authUrl) {
      dispatch(getAuthUrl())
    }
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get("token")
    if (token) {
      dispatch(setToken(token))
      navigate("/forms")
    }
  }, [])

  useEffect(() => {
    if (token) {
      dispatch(getUserInfo())
    }
  }, [token])

  return (
    <div className="home">
      <Hero />
      <Action />
      <Api />
      <Values />
      <ContactUs />
      <GetStarted />
    </div>
  )
}
