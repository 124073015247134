import React, { useEffect } from "react"
import { CustomFormDto } from "../../shared/models/customFormDto"
import "./formsPage.css"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { getFormsAction, setSelectedTab } from "./redux/formsPage.slice"
import {
  selectForms,
  selectIsLoading,
  selectSelectedFormId,
} from "./redux/formsPage.selectors"
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Tab,
} from "@mui/material"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import Box from "@mui/material/Box"
import { SingleFormComponent } from "./formBuilder/SingleFormComponent"
import AddIcon from "@mui/icons-material/Add"
import { resetSpeakToFillResponse } from "../../features/pressToSpeak/redux/voiceRecorder.slice"

export default function FormsPage() {
  const dispatch = useAppDispatch()
  const forms: CustomFormDto[] = useAppSelector(selectForms)

  const selectedTabId = useAppSelector(selectSelectedFormId)
  const isLoading = useAppSelector(selectIsLoading)

  useEffect(() => {
    dispatch(getFormsAction())
  }, [])

  const onTabChange = (event: React.SyntheticEvent, tabId: number) => {
    dispatch(resetSpeakToFillResponse())
    dispatch(setSelectedTab(tabId))
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    }
  }

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {forms.length === 0 && !(selectedTabId === "newForm") ? (
        <Container
          component="section"
          sx={{ display: "flex", justifyContent: "space-evenly", mt: 5, mb: 1 }}
        >
          <Button
            variant="outlined"
            size="large"
            endIcon={<AddIcon sx={{ fontSize: 40 }} />}
            onClick={() => dispatch(setSelectedTab("newForm"))}
          >
            Create
          </Button>
        </Container>
      ) : (
        <Container
          component="section"
          sx={{ display: "flex", justifyContent: "space-evenly", mt: 3, mb: 1 }}
          style={{ paddingLeft: "0", paddingRight: "0" }}
        >
          <div style={{ width: "100%" }}>
            <TabContext value={selectedTabId || ""}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  variant="scrollable"
                  scrollButtons="auto"
                  onChange={onTabChange}
                  aria-label="lab API tabs example"
                >
                  {forms.map((form) => (
                    <Tab
                      key={"tablist" + form?._id}
                      label={form.form_name}
                      value={form?._id + ""}
                    />
                  ))}
                  <Tab icon={<AddIcon />} value={"newForm"} />
                </TabList>
              </Box>
              {forms.map((form) => (
                <TabPanel
                  key={form?._id}
                  value={form?._id + ""}
                  sx={{ p: 0, pt: 3 }}
                >
                  <SingleFormComponent form={form} />
                </TabPanel>
              ))}
              <TabPanel value={"newForm"} sx={{ p: 0, pt: 3 }}>
                <SingleFormComponent form={undefined} />
              </TabPanel>
            </TabContext>
          </div>
        </Container>
      )}
    </>
  )
}
