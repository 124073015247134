import React, { useEffect, useState } from "react"
import { CustomFormDto } from "../../../shared/models/customFormDto"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { selectDemoForms } from "../../../redux/common.selectors"
import { getDemoForms } from "../../../redux/common.slice"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import Box from "@mui/material/Box"
import { Grid, Skeleton, Tab } from "@mui/material"
import { resetSpeakToFillResponse } from "../../../features/pressToSpeak/redux/voiceRecorder.slice"
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
import { Block } from "../../../features/block/Block"
import { DemoRequestCodeSnippet } from "../../../features/codeSnippet/RequestCodeSnippet/DemoRequestCodeSnippet"

export function Api() {
  const dispatch = useAppDispatch()
  const demoForms: CustomFormDto[] = useAppSelector(selectDemoForms)

  const [selectedTab, setSelectedTab] = useState<string | null>(null)

  useEffect(() => {
    dispatch(getDemoForms())
    dispatch(resetSpeakToFillResponse())
  }, [])

  useEffect(() => {
    if (demoForms.length > 0) {
      setSelectedTab(demoForms[0]._id)
    }
  }, [demoForms])

  const onTabChange = (event: React.SyntheticEvent, tabId: string) => {
    dispatch(resetSpeakToFillResponse())
    setSelectedTab(tabId)
  }

  return (
    <Block id="api" title={"Integrate SpeakFill"}>
      <Container
        component="section"
        style={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <Typography variant="h5">SpeakFill is not just a tool.</Typography>
            <Typography variant="h5">It's a powerful API.</Typography>
            <Typography variant="subtitle1" sx={{ mt: "50px", mb: "50px" }}>
              Whether you're building a healthcare platform, a CRM system, or
              any application that requires form inputs, our API seamlessly
              integrates to transform your forms into voice-activated smart
              forms. By embedding SpeakFill into your digital ecosystem, you can
              enhance user experience and boost data input efficiency.
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: "50px", mb: "50px" }}>
            Use this API service to see the magic unfold!
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            {selectedTab ? (
              <TabContext value={selectedTab || ""}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={onTabChange}
                    aria-label="lab API tabs example"
                  >
                    {demoForms.map((form) => (
                      <Tab
                        key={"demoForms" + form?._id}
                        label={form.form_name}
                        value={form?._id + ""}
                      />
                    ))}
                  </TabList>
                </Box>
                {demoForms.map((form) => (
                  <TabPanel
                    key={form?._id}
                    value={form?._id + ""}
                    sx={{ p: 0, pt: 3 }}
                  >
                    <DemoRequestCodeSnippet form={form} />
                  </TabPanel>
                ))}
              </TabContext>
            ) : (
              <TabContext value={"1"}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList aria-label="lab API tabs example">
                    <Tab
                      label={
                        <Skeleton
                          variant="rectangular"
                          width={210}
                          height={24}
                        />
                      }
                      value={"1"}
                    />
                    <Tab
                      label={
                        <Skeleton
                          variant="rectangular"
                          width={210}
                          height={24}
                        />
                      }
                      value={"2"}
                    />
                  </TabList>
                </Box>
                <TabPanel key={"1"} value={"1"} sx={{ p: 0, pt: 3 }}>
                  <Skeleton variant="rectangular" width="100%" height={300} />
                </TabPanel>
              </TabContext>
            )}
          </Grid>
        </Grid>
      </Container>
    </Block>
  )
}
