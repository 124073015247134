import React, { useState } from "react"
import { FormControl, Select } from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import { CodeSnippet } from "../../codeSnippet/CodeSnippet"
import { BASE_URL } from "../../../config/axios.config"
import { CustomFormDto } from "../../../shared/models/customFormDto"

export interface IDemoRequestCodeSnippetProps {
  form: CustomFormDto
}

export const DemoRequestCodeSnippet: React.FC<IDemoRequestCodeSnippetProps> = ({
  form,
}) => {
  const getRequestCode = (lang: string): string => {
    switch (lang) {
      case "bash":
        return (
          "curl -X 'POST' \\\n" +
          "  '" +
          BASE_URL +
          "voice2form/" +
          form?._id +
          "' \\\n" +
          "  -H 'accept: application/json' \\\n" +
          "  -H 'Content-Type: multipart/form-data' \\\n" +
          "  -F 'file=@recorded_audio.wav;type=text/plain'"
        )
      case "javascript":
        return `
const formData = new FormData();
formData.append('file', new File(['recorded_audio.wav'], 'recorded_audio.wav', { type: 'text/plain' }));

fetch(\`${BASE_URL}voice2form/${form?._id}\`, {
  method: 'POST',
  headers: {
    'accept': 'application/json'
  },
  body: formData
})
.then(response => response.json())
.then(data => console.log(data))
.catch(error => console.error('Error:', error));`
      case "python":
        return `import requests
headers = {
    'accept': 'application/json',
    'Content-Type': 'multipart/form-data'
}
files = {
    'file': ('recorded_audio.wav', open('recorded_audio.wav', 'rb'), 'text/plain')
}
response = requests.post(f"${BASE_URL}voice2form/${form?._id}", headers=headers, files=files)
print(response.json())
`
      default:
        return ""
    }
  }
  const [lang, setLang] = useState("bash")
  const [code, setCode] = useState(getRequestCode(lang))
  const handleLanguageChange = (event: any) => {
    setLang(event.target.value)
    setCode(getRequestCode(event.target.value))
  }

  return (
    <div style={{ position: "relative" }}>
      {form?._id && (
        <>
          <CodeSnippet language={lang} id={"request"} code={code} />
          <FormControl
            sx={{
              position: "absolute",
              top: 0,
              right: 16,
            }}
            variant="standard"
            style={{ marginBottom: "1em" }}
          >
            <Select value={lang} onChange={handleLanguageChange}>
              <MenuItem value={"javascript"}>JavaScript</MenuItem>
              <MenuItem value={"python"}>Python</MenuItem>
              <MenuItem value={"bash"}>cURL</MenuItem>
            </Select>
          </FormControl>
        </>
      )}
    </div>
  )
}
