import React from "react"
import { Grid } from "@mui/material"
import Typography from "@mui/material/Typography"
import { Block } from "../../../features/block/Block"

export function Values() {
  return (
    <Block id={"values"} title={"Why SpeakFill"}>
      <Grid container spacing={5} sx={{ display: "flex" }}>
        <Grid item xs={12} md={6} alignContent={"left"}>
          <Typography gutterBottom variant="h5" component="div" sx={{ mb: 3 }}>
            Save Time & Improve Accuracy
          </Typography>
          <Typography variant="subtitle1">
            Eliminate the tedious task of manual data entry. With SpeakFill,
            you're not just speeding up the form-filling process but also
            ensuring that the data captured is accurate, reducing the margin for
            human error.
          </Typography>
        </Grid>
        <Grid item md={6} sx={{ display: { xs: "none", sm: "block" } }} />
        <Grid item md={6} sx={{ display: { xs: "none", sm: "block" } }} />
        <Grid item xs={12} md={6}>
          <Typography gutterBottom variant="h5" component="div" sx={{ mb: 3 }}>
            Truly Hands-Free Forms
          </Typography>
          <Typography variant="subtitle1">
            Inclusivity is at the core of SpeakFill. Our technology ensures that
            everyone, including those with mobility or vision challenges, can
            effortlessly fill out forms. A voice-driven approach means no
            typing, no touch - just speak.
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography gutterBottom variant="h5" component="div" sx={{ mb: 3 }}>
            Easy Integration into Your Systems
          </Typography>
          <Typography variant="subtitle1">
            Whether you're a startup or an established enterprise, SpeakFill
            seamlessly integrates into your existing tech stack. Built to handle
            varying volumes, it's a solution that grows with your needs,
            ensuring consistent performance and reliability.
          </Typography>
        </Grid>
        <Grid item md={6} sx={{ display: { xs: "none", sm: "block" } }} />
      </Grid>
    </Block>
  )
}
