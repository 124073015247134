import React from "react"
import { Button, Grid } from "@mui/material"
import Typography from "@mui/material/Typography"
import { Link } from "react-router-dom"
import { Block } from "../../../features/block/Block"

export function GetStarted() {
  return (
    <Block
      id="get-started"
      title={
        <Grid
          container
          spacing={5}
          sx={{ display: "flex" }}
          style={{ height: "100%" }}
        >
          <Grid
            container
            item
            xs={12}
            md={7}
            display={"flex"}
            direction={"column"}
            alignContent={"space-around"}
            justifyContent={"space-around"}
          >
            <Typography variant="h4" align={"center"} gutterBottom>
              Want to SpeakFill your form?
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={5}
            display={"flex"}
            direction={"column"}
            alignContent={"space-around"}
            justifyContent={"space-around"}
          >
            <Link to={"/forms"}>
              <Button variant="outlined" size="large">
                Let's Go
              </Button>
            </Link>
          </Grid>
        </Grid>
      }
    >
      <></>
    </Block>
  )
}
