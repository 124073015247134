export const languagesHandledByGPT = {
  en: "English",
  es: "Spanish",
  fr: "French",
  de: "German",
  zh: "Chinese",
  ja: "Japanese",
  ru: "Russian",
  pt: "Portuguese",
  it: "Italian",
  ar: "Arabic",
  hi: "Hindi",
  nl: "Dutch",
  ko: "Korean",
  sv: "Swedish",
}

export type LanguageAbbreviation = keyof typeof languagesHandledByGPT
