import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { initialState } from "./voiceRecorder.state"
import { axiosTuul } from "../../../config/axios.config"
import { FormValues } from "../../../shared/models/FormValues"

export const sendSpeakFill = createAsyncThunk(
  "voiceRecorder/sendSpeakFill",
  async (input: { formId: string; formData: FormData; demo: boolean }) => {
    const response = await axiosTuul.post<FormValues>(
      "/speakfill/" + input.formId,
      input.formData,
    )
    return { response: response.data, demo: input.demo }
  },
)
export const sendSpeakFillDemo = createAsyncThunk(
  "voiceRecorder/sendSpeakFill",
  async (input: { formId: string; formData: FormData; demo: boolean }) => {
    const response = await axiosTuul.post<FormValues>(
      "/voice2form/" + input.formId,
      input.formData,
    )
    return { response: response.data, demo: input.demo }
  },
)

export const voiceRecorderSlice = createSlice({
  name: "voiceRecorder",
  initialState,
  reducers: {
    setRecording: (state, action) => {
      state.recording = action.payload
    },
    resetSpeakToFillResponse: (state) => {
      state.speakToFillResponse = undefined
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendSpeakFill.pending, (state) => {
        state.elementsToLoad.push("speekToFill")
      })
      .addCase(sendSpeakFill.fulfilled, (state, action) => {
        state.speakToFillResponse = action.payload.response

        state.elementsToLoad = state.elementsToLoad.filter(
          (element) => element !== "speekToFill",
        )
      })
      .addCase(sendSpeakFill.rejected, (state, action) => {
        state.elementsToLoad = state.elementsToLoad.filter(
          (element) => element !== "speekToFill",
        )
      })
  },
})

export const { setRecording, resetSpeakToFillResponse } =
  voiceRecorderSlice.actions

export default voiceRecorderSlice.reducer
