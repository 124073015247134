import React, { useEffect } from "react"
import { CustomFormField } from "../../shared/models/customFormField"
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  TextField,
} from "@mui/material"
import { FormAttribute } from "../../shared/enum/formAttribute"
import { DateField, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import MenuItem from "@mui/material/MenuItem"

interface ICustomAttribute {
  field: CustomFormField
  value: any
  handleChange: (fieldName: string, value: any) => void
}

export const CustomAttribute: React.FC<ICustomAttribute> = ({
  field,
  value,
  handleChange,
}) => {
  let inputField

  useEffect(() => {}, [value])

  switch (field.type) {
    case FormAttribute.string:
      inputField = (
        <TextField
          sx={{ m: 2 }}
          variant="standard"
          onChange={(e) => handleChange(field.name, e.target.value)}
          label={field.name}
          value={value || ""}
          helperText={field.description || ""}
        />
      )
      break
    case FormAttribute.number:
      inputField = (
        <TextField
          sx={{ m: 2 }}
          variant="standard"
          type="number"
          onChange={(e) => handleChange(field.name, e.target.value)}
          label={field.name}
          value={value || ""}
          helperText={field.description || ""}
        />
      )
      break
    case FormAttribute.boolean:
      inputField = (
        <FormControlLabel
          labelPlacement="top"
          control={
            <Checkbox
              checked={value ? true : false} // value at the beginning is undefined == uncontrolled switch state
              onChange={(e) => handleChange(field.name, e.target.checked)}
            />
          }
          label={field.name}
        />
      )
      break
    case FormAttribute.date:
      inputField = (
        <>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateField
              sx={{ m: 2 }}
              variant={"standard"}
              onChange={(newValue) => handleChange(field.name, newValue)}
              label={field.name}
              value={value}
              format="DD-MM-YYYY"
              helperText={field.description || ""}
            />
          </LocalizationProvider>
        </>
      )
      break
    case FormAttribute.enum:
      inputField = (
        <FormControl sx={{ m: 2, minWidth: 182 }}>
          <InputLabel variant="standard">{field.name}</InputLabel>
          <Select
            variant={"standard"}
            value={value ? value : ""}
            onChange={(e) => handleChange(field.name, e.target.value)}
          >
            {field.enum?.map((enumValue) => (
              <MenuItem key={"enum" + field.name + enumValue} value={enumValue}>
                {enumValue}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
      break
  }

  return <>{inputField}</>
}
