import React from "react"
import Snackbar from "@mui/material/Snackbar"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import { Paper, Typography } from "@mui/material"
import { selectIsDevMode } from "../../redux/common.selectors"
import { useAppSelector } from "../../app/hooks"

interface DevModeProps {
  children: React.ReactNode
}

export const DevMode: React.FC<DevModeProps> = ({ children }) => {
  const devMode = useAppSelector(selectIsDevMode)

  const [open, setOpen] = React.useState(true)

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return
    }
    setOpen(false)
  }

  return (
    <Snackbar
      open={devMode && open}
      onClose={handleClose}
      message="Note archived"
    >
      <Paper elevation={3} sx={{ p: 1 }}>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Dev mode feature
        </Typography>
        {children}
        <div>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      </Paper>
    </Snackbar>
  )
}
