import React from "react"
import "./CustomTooltip.css"
import Typography from "@mui/material/Typography"

// create interface
interface ICustomTooltip {
  text: string | undefined
  visible: boolean | undefined
  children: React.ReactNode
}

const CustomTooltip: React.FC<ICustomTooltip> = ({
  text,
  visible,
  children,
}) => {
  return (
    <div className="container">
      <div className="children">{children}</div>
      {text && visible && (
        <div className="tooltip-box">
          <Typography variant="body2" align={"center"}>
            {text}
          </Typography>
          <div className="tooltip-arrow"></div>
        </div>
      )}
    </div>
  )
}

export default CustomTooltip
