import { CustomFormDto } from "../shared/models/customFormDto"
import { VariantType } from "notistack"
import { UserDto } from "../shared/models/userDto"

export type elementToLoad = "demoForms"

export interface CommonState {
  devMode: boolean
  demoForms: CustomFormDto[]
  authUrl?: string
  token?: string
  toasts: { message: string; variant: VariantType }[]
  elementsToLoad: elementToLoad[]
  user?: UserDto
}

export const initialState: CommonState = {
  devMode: false,
  demoForms: [],
  authUrl: undefined,
  token: undefined,
  toasts: [],
  elementsToLoad: [],
  user: undefined,
}
