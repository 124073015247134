import { CustomAttribute } from "../customAttribute/customAttribute"
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import Typography from "@mui/material/Typography"
import "./customForm.css"
import VoiceRecorder from "../pressToSpeak/voiceRecorder"
import { useAppSelector } from "../../app/hooks"
import {
  selectIsLoading,
  selectSpeakToFillResponse,
} from "../pressToSpeak/redux/voiceRecorder.selectors"
import { green } from "@mui/material/colors"
import { FormValues } from "../../shared/models/FormValues"
import { CustomFormDto } from "../../shared/models/customFormDto"
import { CustomFormField } from "../../shared/models/customFormField"
import moment from "moment"

interface ICustomForm {
  form: CustomFormDto
  disabled?: boolean
  demoTooltip?: boolean
}

export const CustomForm: React.FC<ICustomForm> = ({
  form,
  disabled,
  demoTooltip,
}) => {
  const isLoading = useAppSelector(selectIsLoading)
  const speakToFillResponse = useAppSelector(selectSpeakToFillResponse)
  const [values, setValues] = useState<FormValues>({})

  useEffect(() => {
    if (speakToFillResponse) {
      Object.entries(speakToFillResponse).forEach((response) => {
        const formField: CustomFormField = form.form_fields.filter(
          (field) => field.name === response[0],
        )[0]
        if (formField.type === "date" && response[1]) {
          handleChange(response[0], moment(response[1] + "", "RRRR-MM-DD"))
        } else {
          handleChange(response[0], response[1])
        }
      })
    }
  }, [speakToFillResponse])

  const handleChange = (fieldName: string, value: any) => {
    if (value !== undefined && value !== null && value !== "") {
      values[fieldName] = value
      setValues({ ...values })
    }
  }

  const onClear = () => {
    setValues({})
  }

  return (
    <div
      className={disabled || isLoading ? "disabled-div" : ""}
      style={{ position: "relative" }}
    >
      {isLoading && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-40px ,-40px) ",
          }}
        >
          <CircularProgress
            size={80}
            sx={{
              color: green[500],
              zIndex: 100,
            }}
          />
        </Box>
      )}
      <Paper elevation={2} sx={{ p: 2, pt: 1, m: 0 }}>
        <Grid container rowSpacing={1}>
          <Grid item xs={12} sm={7}>
            <Typography variant="h6" align={"center"}>
              {form.form_name}
            </Typography>
            {form.form_fields.map((field) => (
              <CustomAttribute
                key={field.name}
                handleChange={handleChange}
                field={field}
                value={values[field.name]}
              />
            ))}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                disableElevation
                variant="outlined"
                size="large"
                type="submit"
                color="primary"
                onClick={() => onClear()}
              >
                Clear
              </Button>
            </div>
          </Grid>
          <Grid item xs={0} sm={1 / 10}>
            <Divider orientation={"vertical"} style={{ width: "100%" }} />
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={49 / 10}
            display={"flex"}
            direction="column"
            alignItems={"center"}
            justifyContent={"space-around"}
          >
            <VoiceRecorder
              demoTooltip={demoTooltip}
              tooltipText={demoTooltip ? form.tooltip : undefined}
              formId={form._id}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}
