import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { initialState } from "./common.state"
import { axiosTuul } from "../config/axios.config"
import { VariantType } from "notistack"
import { CustomFormDto } from "../shared/models/customFormDto"
import { UserDto } from "../shared/models/userDto"

export const getAuthUrl = createAsyncThunk("common/getAuthUrl", async () => {
  const response = await axiosTuul.get<string>("/auth/authorization_url")
  return response.data
})

export const getDemoForms = createAsyncThunk(
  "common/getDemoForms",
  async () => {
    const response = await axiosTuul.get<CustomFormDto[]>("/demo/forms")
    return response.data
  },
)

export const getUserInfo = createAsyncThunk("common/getUserInfo", async () => {
  const response = await axiosTuul.get<UserDto>("/me")
  return response.data
})

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload
    },
    showToast: (
      state,
      action: PayloadAction<{ message: string; variant: VariantType }>,
    ) => {
      state.toasts.push(action.payload)
    },
    removeFirstToast: (state) => {
      state.toasts.shift()
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAuthUrl.pending, (state) => {})
      .addCase(getAuthUrl.fulfilled, (state, action) => {
        state.authUrl = action.payload
      })
      .addCase(getAuthUrl.rejected, (state) => {})
      .addCase(getDemoForms.pending, (state) => {
        state.elementsToLoad.push("demoForms")
      })
      .addCase(getDemoForms.fulfilled, (state, action) => {
        state.demoForms = action.payload
        state.elementsToLoad = state.elementsToLoad.filter(
          (element) => element !== "demoForms",
        )
      })
      .addCase(getDemoForms.rejected, (state) => {
        state.elementsToLoad = state.elementsToLoad.filter(
          (element) => element !== "demoForms",
        )
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.user = action.payload
      })

      .addMatcher(
        (action) => {
          return action.type.endsWith("/rejected")
        },
        (state, action) => {
          console.log(action)
          state.toasts.push({
            message: action.error.message || action.type,
            variant: "error",
          })
        },
      )
  },
})

export const { setToken, showToast, removeFirstToast } = commonSlice.actions

export default commonSlice.reducer
