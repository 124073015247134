import React, { useEffect } from "react"

import { Outlet, useLocation } from "react-router-dom"
import AppBar from "./appBar"
import Footer from "./footer"
import { enqueueSnackbar, SnackbarProvider } from "notistack"
import { useAppDispatch } from "../../app/hooks"
import { useSelector } from "react-redux"
import { selectToasts } from "../../redux/common.selectors"
import { removeFirstToast } from "../../redux/common.slice"
import "./layout.css"

const Layout = () => {
  const dispatch = useAppDispatch()

  const toasts = useSelector(selectToasts)

  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  useEffect(() => {
    if (toasts.length > 0) {
      const toast = toasts[0]
      enqueueSnackbar(toast.message, { variant: toast.variant })
      dispatch(removeFirstToast())
    }
  }, [toasts])

  return (
    <div className="layout">
      <AppBar />
      <div
        className="content"
        style={{
          marginTop: "83px",
          padding: "20px",
        }}
      >
        <Outlet />
      </div>
      <SnackbarProvider maxSnack={5} />
      <div className="footer">
        <Footer />
      </div>
    </div>
  )
}

export default Layout
