import React from "react"
import { Button, Grid } from "@mui/material"
import Typography from "@mui/material/Typography"
import Container from "@mui/material/Container"
import { Link } from "react-router-dom"
import Animation_hero from "../../../shared/img/Animation_hero.json"
import { useLottie } from "lottie-react"

export function Hero() {
  const lottieOptions = {
    loop: true,
    animationData: Animation_hero,
  }
  const { View } = useLottie(lottieOptions)

  return (
    <Container
      maxWidth="xl"
      sx={{ mt: 3, mb: 1 }}
      style={{ height: "calc(100vh - 230px)" }}
      className={"hero"}
    >
      <Grid container spacing={5} sx={{ display: "flex" }}>
        <Grid
          container
          item
          xs={12}
          md={7}
          xl={6}
          display={"flex"}
          direction={"column"}
          justifyContent={"flex-start"}
        >
          <Typography variant="h1" sx={{ mb: "24px" }}>
            Future of hands free data entry
          </Typography>
          <div className={"para"}>
            SpeakFill API transforms spoken words into structured data. Use
            voice to effortlessly populate form fields, making data entry swift,
            accurate, and truly hands-free.
          </div>
          <Link to={"/forms"}>
            <Button sx={{ mt: "40px" }} variant="outlined" size="large">
              Start for free
            </Button>
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          xl={6}
          display={"flex"}
          alignContent={"center"}
          justifyContent={"center"}
        >
          {View}
        </Grid>
      </Grid>
    </Container>
  )
}
