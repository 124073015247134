import React from "react"
import Typography from "@mui/material/Typography"
import Container from "@mui/material/Container"

interface IBlock {
  title: string | React.ReactNode
  children: React.ReactNode
  id: string
}

export const Block: React.FC<IBlock> = ({ title, children, id }) => {
  return (
    <>
      <Container
        id={id}
        sx={{ mt: 10, mb: 1 }}
        style={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <Typography variant="h4" align={"center"} sx={{ mb: 5, mt: 2 }}>
          {title}
        </Typography>
        <>{children}</>
      </Container>
    </>
  )
}
