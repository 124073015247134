import { RootState } from "../../../app/store"
import { CustomFormDto } from "../../../shared/models/customFormDto"

export const selectForms = (state: RootState) => state.formsPage.forms
export const selectSelectedFormId = (state: RootState) =>
  state.formsPage.selectedFormId
export const selectIsLoading = (state: RootState) =>
  state.formsPage.elementsToLoad.length > 0

export const selectSelectedForm = (state: RootState): CustomFormDto => {
  const forms = selectForms(state)
  const selectedFormId = selectSelectedFormId(state)
  return (
    forms.find((form) => form._id?.toString() === selectedFormId) || forms[0]
  )
}
