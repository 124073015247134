import { useAppSelector } from "../app/hooks"
import { selectAuthUrl } from "../redux/common.selectors"
import React, { useEffect } from "react"
import { CircularProgress, Container } from "@mui/material"
import Typography from "@mui/material/Typography"
import { green } from "@mui/material/colors"

export const RedirectToLoginPage = () => {
  const authUrl = useAppSelector(selectAuthUrl)
  useEffect(() => {
    window.location.href = authUrl || "/"
  }, [])

  return (
    <Container component="section" sx={{ mt: 7, mb: 1 }}>
      <Typography variant="h2">
        Redirecting to login page
        <CircularProgress
          size={50}
          sx={{
            color: green[500],
            zIndex: 100,
          }}
        />
      </Typography>
    </Container>
  )
}
