import React, { useEffect, useState } from "react"
import { CustomFormDto } from "../../../shared/models/customFormDto"
import { CustomForm } from "../../../features/customForm/customForm"
import { FormCreator } from "./fieldsCreator/FormCreator"
import Typography from "@mui/material/Typography"
import { CodeSnippet } from "../../../features/codeSnippet/CodeSnippet"
import { Grid } from "@mui/material"
import { Block } from "../../../features/block/Block"
import { useAppSelector } from "../../../app/hooks"
import { selectUser } from "../../../redux/common.selectors"
import { RequestCodeSnippet } from "../../../features/codeSnippet/RequestCodeSnippet/RequestCodeSnippet"

interface ISingleFormComponent {
  form: CustomFormDto | undefined
}

export const SingleFormComponent: React.FC<ISingleFormComponent> = ({
  form,
}) => {
  const user = useAppSelector(selectUser)
  const getEmptyForm = (): CustomFormDto => {
    return {
      _id: null,
      form_name: "New Form",
      form_fields: [],
      language: "en",
    }
  }

  const [newForm, setNewForm] = useState<CustomFormDto>(
    form ? JSON.parse(JSON.stringify(form)) : getEmptyForm(),
  )
  const [dirtyForm, setDirtyForm] = useState<boolean>(
    !form || form?._id === null,
  )

  useEffect(() => {
    setNewForm(form ? JSON.parse(JSON.stringify(form)) : getEmptyForm())
    setDirtyForm(!form || form?._id === null)
  }, [form])

  const getResponseCode = (): string =>
    "{\n" +
    newForm.form_fields
      .map((field) => ' "' + field.name + '": ' + field.type + ",\n")
      .join("") +
    "}"

  return (
    <>
      <FormCreator
        newForm={newForm}
        setDirtyForm={setDirtyForm}
        setNewForm={setNewForm}
        dirtyForm={dirtyForm}
      />

      <Block title={"Test form"} id={"test-form"}>
        <CustomForm form={newForm} disabled={dirtyForm} />
      </Block>

      {!form || form?._id === null ? (
        <Block title={"API"} id={"API"}>
          <Typography variant="h5" align={"center"}>
            Initialize the form first...
          </Typography>
        </Block>
      ) : (
        <Block title={"API"} id={"API"}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={8}>
              <Typography variant="h5" align={"center"}>
                Request
              </Typography>
              <RequestCodeSnippet form={newForm} apiKey={user?.api_key + ""} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h5" align={"center"}>
                Response
              </Typography>
              <CodeSnippet
                language="javascript"
                id={"response"}
                code={getResponseCode()}
              />
            </Grid>
            {user?.api_key && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  display={"flex"}
                  flexDirection={"column"}
                  alignContent={"center"}
                  justifyContent={"center"}
                >
                  <Typography variant="h5" align={"center"}>
                    Key
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Typography variant="caption" align={"center"}>
                    <CodeSnippet
                      language="json"
                      id={"response"}
                      code={user.api_key}
                    />
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Block>
      )}
    </>
  )
}
