import axios from "axios"
import { store } from "../app/store"
import { setToken, showToast } from "../redux/common.slice"

export const BASE_URL = "https://api.speakfill.com/"
export const axiosTuul = axios.create({
  baseURL: BASE_URL,
  timeout: 100000,
  // headers: { "X-Custom-Header": "foobar" },
})

const useApiKey = (config: any) => {
  return config.url?.startsWith("/speakfill/")
}

axiosTuul.interceptors.request.use(
  async (config) => {
    // if (!store.getState().common.token) {
    //   store.dispatch(setToken(accessToken))
    //   config.headers.Authorization = "Bearer " + accessToken
    // } else {
    //   config.headers.Authorization = "Bearer " + store.getState().common.token
    // }

    config.headers.Authorization =
      "Bearer " +
      (useApiKey(config)
        ? store.getState().common.user?.api_key
        : store.getState().common.token)

    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

axiosTuul.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    const originalRequest = error.config

    // if (
    //   error.response &&
    //   error.response.status === 401 &&
    //   !originalRequest._retry
    // ) {
    //   originalRequest._retry = true
    //   store.dispatch(setToken(undefined))
    //   return axiosTuul(originalRequest)
    // }
    if (error.response && 401 === error.response.status) {
      store.dispatch(setToken(undefined))
      window.location.href = "/"
      store.dispatch(
        showToast({ message: "Please login again", variant: "error" }),
      )
      // auth0Client.logout({
      //   logoutParams: { returnTo: window.location.origin },
      // })
    }
    return Promise.reject(error)
  },
)
