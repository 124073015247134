import { CustomFormDto } from "../../../../shared/models/customFormDto"
import React, { useEffect } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
} from "@mui/material"
import {
  createFormAction,
  deleteFormAction,
  updateFormAction,
} from "../../redux/formsPage.slice"
import { useAppDispatch } from "../../../../app/hooks"
import { FormFieldsCreator } from "./FormFieldsCreator"
import { CustomFormField } from "../../../../shared/models/customFormField"
import MenuItem from "@mui/material/MenuItem"
import { languagesHandledByGPT } from "../../../../shared/models/languages"

interface IFormCreator {
  dirtyForm: boolean
  setDirtyForm: (dirty: boolean) => void
  newForm: CustomFormDto
  setNewForm: (form: CustomFormDto) => void
}

export const FormCreator: React.FC<IFormCreator> = ({
  dirtyForm,
  setDirtyForm,
  newForm,
  setNewForm,
}) => {
  const dispatch = useAppDispatch()
  const [allFieldsValid, setAllFieldsValid] = React.useState(true)
  const [disableSubmitTooltip, setDisableSubmitTooltip] = React.useState("")

  const validateForm = () => {
    setAllFieldsValid(true)
    setDisableSubmitTooltip("")
    if (
      !newForm.form_name ||
      newForm.form_fields.find((field) => !field.name) ||
      newForm.form_fields
        .filter((field) => field.type === "enum")
        .flatMap((field) => field.enum)
        .some((enumItem) => !enumItem)
    ) {
      setAllFieldsValid(false)
      setDisableSubmitTooltip("Please fill all required fields")
    }

    if (
      newForm.form_fields
        .filter((field) => field.type === "enum")
        .find((field) => !field.enum || field.enum.length === 0)
    ) {
      setAllFieldsValid(false)
      setDisableSubmitTooltip("Please add at least one dropdown value")
    }
  }

  useEffect(() => {
    validateForm()
  }, [newForm])

  const changeFormName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDirtyForm(true)
    newForm.form_name = event.target.value
    setNewForm({ ...newForm })
  }

  const submitForm = () => {
    setDirtyForm(false)
    dispatch(
      newForm._id ? updateFormAction(newForm) : createFormAction(newForm),
    )
  }

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const setFields = (items: CustomFormField[]): void => {
    newForm.form_fields = items
    setNewForm({ ...newForm })
  }

  const handleLanguageChange = (event: any) => {
    setDirtyForm(true)
    newForm.language = event.target.value
    setNewForm({ ...newForm })
  }

  return (
    <div>
      <Grid container spacing={2} sx={{ display: "flex", mb: 1, mt: 1 }}>
        <Grid item xs={6} md={3} alignContent={"center"}>
          <TextField
            style={{ width: "100%" }}
            onChange={changeFormName}
            variant="filled"
            required
            label={"Name"}
            value={newForm.form_name}
            error={!newForm.form_name}
            helperText={!newForm.form_name && "Incorrect entry"}
          />
        </Grid>
        <Grid item xs={6} md={3} alignContent={"center"}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-label">{"Language"}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              variant="filled"
              value={newForm.language}
              label={"Language"}
              onChange={handleLanguageChange}
            >
              {Object.entries(languagesHandledByGPT).map((lang) => (
                <MenuItem key={lang[0]} value={lang[0]}>
                  {lang[1]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} alignContent={"center"}>
          {newForm._id && (
            <Button
              disableElevation
              variant="outlined"
              size="large"
              type="submit"
              color="error"
              onClick={handleClickOpen}
              sx={{ display: "flex", mb: 1, mt: 1 }}
            >
              Remove form
            </Button>
          )}
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Remove " + newForm.form_name + " form"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Do you really want to remove this form?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                onClick={() => {
                  handleClose()
                  dispatch(deleteFormAction(newForm._id || ""))
                }}
                autoFocus
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
      <FormFieldsCreator
        setDirtyForm={setDirtyForm}
        items={newForm.form_fields}
        setItems={setFields}
        disableSubmitButton={!allFieldsValid || !dirtyForm}
        onSubmit={submitForm}
        disableSubmitTooltip={disableSubmitTooltip}
      />
    </div>
  )
}
