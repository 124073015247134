import React from "react"
import { Container } from "@mui/material"
import Typography from "@mui/material/Typography"
import markdown from "./PrivacyPolicy.md"
import ReactMarkdown from "react-markdown"

export default function PrivacyPolicy() {
  return (
    <Container component="section" sx={{ mt: 1, mb: 1 }}>
      <Typography gutterBottom variant="h3" sx={{ mb: 5 }}>
        Privacy and Cookie Policy
      </Typography>
      <Typography component="div">
        <ReactMarkdown children={markdown}></ReactMarkdown>
      </Typography>
    </Container>
  )
}
