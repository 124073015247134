import * as React from "react"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Menu from "@mui/material/Menu"
import MenuIcon from "@mui/icons-material/Menu"
import Container from "@mui/material/Container"
import Button from "@mui/material/Button"
import MenuItem from "@mui/material/MenuItem"
import logo from "../../shared/img/logo.png"
import { useLocation, useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectIsAuthenticated } from "../../redux/common.selectors"
import { setToken } from "../../redux/common.slice"
import "./appBar.scss"
import { AppBar } from "@mui/material"

function ResponsiveAppBar() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const isAuthenticated = useAppSelector(selectIsAuthenticated)

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  )

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const logout = () => {
    dispatch(setToken(undefined))
  }

  const goToParagraph = (paragraphId: string) => {
    if (location.pathname !== "/") {
      navigate("/")
    }
    setTimeout(() => {
      const element = document.getElementById(paragraphId)
      if (element) {
        const rect = element.getBoundingClientRect()
        window.scrollTo({
          top: rect.top + window.scrollY - 100,
          behavior: "smooth",
        })
      }
    }, 0)
  }

  return (
    <div className={"appBar"}>
      <AppBar position="fixed">
        <Container maxWidth="xl" className={"main-container"}>
          <Toolbar disableGutters>
            <div
              onClick={() => {
                navigate("/")
                window.scrollTo(0, 0)
              }}
              className={"logo"}
            >
              <img src={logo} className={"logo-img"} />
            </div>
            <Box
              className={"menu-items-small-screen"}
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {!isAuthenticated ? (
                  <div>
                    <MenuItem
                      onClick={() => {
                        handleCloseNavMenu()
                        goToParagraph("examples")
                      }}
                    >
                      <Typography textAlign="center">{"Use Cases"}</Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleCloseNavMenu()
                        goToParagraph("api")
                      }}
                    >
                      <Typography textAlign="center">{"API"}</Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleCloseNavMenu()
                        goToParagraph("contact-us")
                      }}
                    >
                      <Typography textAlign="center">{"Contact Us"}</Typography>
                    </MenuItem>
                  </div>
                ) : (
                  <div>
                    <MenuItem
                      onClick={() => {
                        handleCloseUserMenu()
                        navigate("/forms")
                      }}
                    >
                      <Typography textAlign="center">
                        {"Form builder"}
                      </Typography>
                    </MenuItem>
                  </div>
                )}
              </Menu>
            </Box>
            <Box
              className={"menu-items-big-screen"}
              sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
            >
              {!isAuthenticated ? (
                <>
                  <Button
                    onClick={() => {
                      handleCloseNavMenu()
                      goToParagraph("examples")
                    }}
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    {"Use Cases"}
                  </Button>
                  <Button
                    onClick={() => {
                      handleCloseNavMenu()
                      goToParagraph("api")
                    }}
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    {"API"}
                  </Button>
                  <Button
                    onClick={() => {
                      handleCloseNavMenu()
                      goToParagraph("contact-us")
                    }}
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    {"Contact Us"}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      handleCloseUserMenu()
                      navigate("/forms")
                    }}
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    {"Form builder"}
                  </Button>
                </>
              )}
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              {!isAuthenticated ? (
                <Button
                  className={"login-button"}
                  onClick={() => navigate("/login")}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  {"Log in"}
                </Button>
              ) : (
                <Button
                  onClick={logout}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  {"Log out"}
                </Button>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  )
}

export default ResponsiveAppBar
