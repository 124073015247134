import "./App.scss"
import React, { useEffect } from "react"
import { Home } from "./pages/home/Home"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Layout from "./pages/layout/layout"
import FormsPage from "./pages/formsPage/FormsPage"
import { selectIsAuthenticated } from "./redux/common.selectors"
import { useAppSelector } from "./app/hooks"
import { RedirectToLoginPage } from "./pages/RedirectToLoginPage"
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material"
import PrivacyPolicy from "./pages/Privacy-Policy/PrivacyPolicy"
import TermsConditions from "./pages/Terms-Conditions/TermsConditions"
import ReactGA from "react-ga4"
import { GA_TRACKING_ID } from "./config/ga.config"

export const ColorModeContext = React.createContext({
  toggleColorMode: () => {},
})

function App() {
  const isAuthenticated = useAppSelector(selectIsAuthenticated)

  useEffect(() => {
    ReactGA.initialize(GA_TRACKING_ID)
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    })
  }, [])

  // const [mode, setMode] = React.useState<"light" | "dark">("dark")
  // const colorMode = React.useMemo(
  //   () => ({
  //     toggleColorMode: () => {
  //       setMode((prevMode) => (prevMode === "light" ? "dark" : "light"))
  //     },
  //   }),
  //   [],
  // )
  const theme = createTheme({
    palette: {
      mode: "dark", // Ustaw tryb na ciemny
      background: {
        default: "#111111",
      },
      primary: {
        main: "#4A90E2",
        contrastText: "#FAFAFA",
      },
      text: {
        primary: "#FAFAFA",
        secondary: "#CCCCCC",
      },
      divider: "#8A8A8A",
      action: {
        active: "#FAFAFA",
      },
      error: {
        main: "#DC3545",
      },
      success: {
        main: "#28A745",
      },
      warning: {
        main: "#FFC107",
      },
    },
    typography: {
      fontFamily: "Archivo",
      button: {
        textTransform: "none",
      },
      h1: {
        fontSize: "60px",
        wordSpacing: "0",
        lineHeight: 1.5,
        letterSpacing: "0",
      },
      h2: {
        fontSize: "55px",
        wordSpacing: "0",
        lineHeight: 1.4,
        letterSpacing: "0",
      },
      h3: {
        fontSize: "50px",
        wordSpacing: "0",
        lineHeight: 1.4,
        letterSpacing: "0",
      },
      h4: {
        fontSize: "40px",
        fontWeight: "bold",
        wordSpacing: "0",
        lineHeight: 1.4,
        letterSpacing: "0",
      },
      h5: {
        fontSize: "25px",
        fontWeight: "700",
        wordSpacing: "0",
        lineHeight: 1.4,
        letterSpacing: "0",
      },
      h6: {
        fontSize: "16px",
        fontWeight: "700",
        wordSpacing: "0",
        lineHeight: 1.4,
        letterSpacing: "0",
      },
      subtitle1: {
        fontSize: "16px",
        fontWeight: "400",
        wordSpacing: "0",
        lineHeight: 1.4,
        letterSpacing: "0",
      },
      body1: {
        fontSize: "16px",
        fontWeight: "400",
        wordSpacing: "0",
        lineHeight: 1.4,
        letterSpacing: "0",
      },
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
            {isAuthenticated ? (
              <>
                <Route index element={<Home />} />
                <Route path="/forms" element={<FormsPage />} />
              </>
            ) : (
              <>
                <Route index element={<Home />} />
                <Route path="/*" element={<RedirectToLoginPage />} />
                <Route path="/login" element={<RedirectToLoginPage />} />
              </>
            )}
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
