export enum FormAttribute {
  string = "string",
  number = "number",
  boolean = "boolean",
  date = "date",
  enum = "enum",
}

export const getFormAttributeName = (i: FormAttribute | undefined): string => {
  switch (i) {
    case FormAttribute.string:
      return "text"
    case FormAttribute.number:
      return "number"
    case FormAttribute.boolean:
      return "checkbox"
    case FormAttribute.date:
      return "date"
    case FormAttribute.enum:
      return "dropdown"
    default:
      return ""
  }
}
