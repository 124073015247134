import { CustomFormDto } from "../../../shared/models/customFormDto"

export type elementToLoad = "forms" | "newForm" | "updateForm" | "removingForm"

export interface FormsPageState {
  forms: CustomFormDto[]
  selectedFormId: string | undefined
  elementsToLoad: elementToLoad[]
}

export const initialState: FormsPageState = {
  forms: [],
  selectedFormId: undefined,
  elementsToLoad: [],
}
