import { Box, Button, Grid } from "@mui/material"
import * as React from "react"
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
import Link from "@mui/material/Link"
import { useNavigate } from "react-router-dom"

function Footer() {
  const navigate = useNavigate()
  return (
    <>
      <Box
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
          p: 6,
          pt: 2,
        }}
      >
        <Container component="section" sx={{ mt: 0, pt: 0, mb: 2 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Button
              variant="text"
              onClick={() => navigate("/terms-conditions")}
            >
              Terms & Conditions{" "}
            </Button>
            <Button variant="text" onClick={() => navigate("/privacy-policy")}>
              Privacy Policy
            </Button>
            <a
              href="https://www.tuul.ai/blog/"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "white" }}
              target={"_blank"}
            >
              <Button variant="text">{"Blog"}</Button>
            </a>
            <a
              href="https://www.tuul.ai/"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "white" }}
              target={"_blank"}
            >
              <Button variant="text">{"tuul.ai"}</Button>
            </a>
          </div>
        </Container>
        <Container component="section" sx={{ mt: 0, pt: 0 }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              display={"flex"}
              alignItems={"flex-end"}
              justifyContent={"space-around"}
            >
              <Typography variant="body2" color="text.secondary" align="center">
                {"Copyright © "}
                <Link color="inherit" href="https://www.tuul.ai/">
                  {"tuul.ai"}
                </Link>{" "}
                {new Date().getFullYear()}
                {"."}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            ></Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default Footer
