import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { initialState } from "./formsPage.state"
import { CustomFormDto } from "../../../shared/models/customFormDto"
import { axiosTuul } from "../../../config/axios.config"

export const createFormAction = createAsyncThunk(
  "formsPage/createForm",
  async (form: CustomFormDto) => {
    const response = await axiosTuul.post<CustomFormDto>("/form", form)
    return response.data
  },
)
export const updateFormAction = createAsyncThunk(
  "formsPage/updateForm",
  async (form: CustomFormDto) => {
    const response = await axiosTuul.put<CustomFormDto>(
      "/form/" + form._id,
      form,
    )
    return response.data
  },
)
export const getFormsAction = createAsyncThunk(
  "formsPage/getForms",
  async () => {
    const response = await axiosTuul.get<CustomFormDto[]>("/forms")
    return response.data
  },
)

export const deleteFormAction = createAsyncThunk(
  "formsPage/deleteFormAction",
  async (formId: string) => {
    await axiosTuul.delete<void>("/form/" + formId)
    return formId
  },
)

export const formsPageSlice = createSlice({
  name: "formsPage",
  initialState,
  reducers: {
    setSelectedTab: (state, action) => {
      state.selectedFormId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createFormAction.pending, (state) => {
        state.elementsToLoad.push("newForm")
      })
      .addCase(createFormAction.fulfilled, (state, action) => {
        const newForm = action.payload
        state.forms.push(newForm)
        state.selectedFormId = newForm._id?.toString()
        state.elementsToLoad = state.elementsToLoad.filter(
          (element) => element !== "newForm",
        )
      })
      .addCase(createFormAction.rejected, (state) => {
        state.elementsToLoad = state.elementsToLoad.filter(
          (element) => element !== "newForm",
        )
      })
      .addCase(updateFormAction.pending, (state) => {
        state.elementsToLoad.push("forms")
      })
      .addCase(updateFormAction.fulfilled, (state, action) => {
        const updatedForm = action.payload
        state.forms = state.forms.map((form) =>
          form._id?.toString() === updatedForm._id?.toString()
            ? updatedForm
            : form,
        )
        state.selectedFormId = updatedForm._id?.toString()
        state.elementsToLoad = state.elementsToLoad.filter(
          (element) => element !== "forms",
        )
      })
      .addCase(updateFormAction.rejected, (state) => {
        state.elementsToLoad = state.elementsToLoad.filter(
          (element) => element !== "forms",
        )
      })
      .addCase(getFormsAction.pending, (state) => {
        state.elementsToLoad.push("updateForm")
      })
      .addCase(getFormsAction.fulfilled, (state, action) => {
        const forms = action.payload
        state.forms = forms
        if (forms && forms.length > 0) {
          state.selectedFormId = forms[0]._id?.toString()
        }
        state.elementsToLoad = state.elementsToLoad.filter(
          (element) => element !== "updateForm",
        )
      })
      .addCase(getFormsAction.rejected, (state) => {
        state.elementsToLoad = state.elementsToLoad.filter(
          (element) => element !== "updateForm",
        )
      })
      .addCase(deleteFormAction.pending, (state) => {
        state.elementsToLoad.push("removingForm")
      })
      .addCase(deleteFormAction.fulfilled, (state, action) => {
        state.forms = state.forms.filter((form) => form._id !== action.payload)
        state.elementsToLoad = state.elementsToLoad.filter(
          (element) => element !== "removingForm",
        )
      })
      .addCase(deleteFormAction.rejected, (state) => {
        state.elementsToLoad = state.elementsToLoad.filter(
          (element) => element !== "removingForm",
        )
      })
  },
})

export const { setSelectedTab } = formsPageSlice.actions

export default formsPageSlice.reducer
