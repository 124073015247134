import React from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material"
import {
  FormAttribute,
  getFormAttributeName,
} from "../../../../shared/enum/formAttribute"
import Tooltip from "@mui/material/Tooltip"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { GridProps } from "@mui/material/Grid/Grid"

interface IFormFieldsCreator {
  disableSubmitButton?: boolean
  onSubmit?: () => void
  disableSubmitTooltip?: string
  setDirtyForm: (dirty: boolean) => void
  items: any[]
  setItems: (items: any[]) => void
  enumCreator?: boolean
}

export const FormFieldsCreator: React.FC<IFormFieldsCreator> = ({
  disableSubmitButton,
  onSubmit,
  disableSubmitTooltip,
  setDirtyForm,
  items,
  setItems,
  enumCreator,
}) => {
  const addNewField = () => {
    setDirtyForm(true)
    if (enumCreator) {
      items.push("")
    } else {
      items.push({
        name: "",
        description: "",
        type: FormAttribute.string,
      })
    }
    setItems(items)
  }

  const removeField = (i: number) => {
    setDirtyForm(true)
    items = items.filter((_, index) => index !== i)
    setItems(items)
  }

  const changeFieldName = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ) => {
    if (enumCreator) {
      items[index] = event.target.value
    } else {
      items[index].name = event.target.value
    }

    setDirtyForm(true)
    setItems(items)
  }

  const changeFieldAttribute = (
    event: SelectChangeEvent<FormAttribute>,
    index: number,
  ) => {
    setDirtyForm(true)
    items[index].type = event.target.value as FormAttribute
    setItems(items)
  }

  const changeFieldDescription = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ) => {
    setDirtyForm(true)
    items[index].description = event.target.value
    setItems(items)
  }

  const setEnums = (enums: string[], i: number): void => {
    items[i].enum = enums
    setItems(items)
  }

  const dropdownAccordion = (field: any, i: number) => {
    return (
      !enumCreator &&
      FormAttribute.enum === field.type && (
        <>
          <Grid xs={0} md={1} />
          <Grid item xs={12} md={4} alignContent={"left"}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Dropdown values</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormFieldsCreator
                  enumCreator
                  items={field.enum || [""]}
                  setDirtyForm={setDirtyForm}
                  setItems={(items: any[]) => {
                    setEnums(items, i)
                  }}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid xs={0} md={7} />
        </>
      )
    )
  }

  const removeFieldButton = (i: number) => {
    return (
      <Button
        disableElevation
        variant="outlined"
        size="large"
        type="submit"
        color="error"
        onClick={() => removeField(i)}
      >
        X
      </Button>
    )
  }

  const removeFieldGridProps = {
    container: true,
    item: true,
    xs: enumCreator ? 3 : 12,
    md: enumCreator ? 3 : 2,
    display: "flex",
    direction: "row",
    alignItems: "center",
    justifyContent: "start",
  } as GridProps

  return (
    <div>
      <Grid container spacing={2} sx={{ display: "flex", mb: 1, mt: 1 }}>
        {items.map((field, i) => (
          <>
            <Grid
              item
              xs={enumCreator ? 9 : 6}
              md={enumCreator ? 9 : 3}
              alignContent={"center"}
            >
              <TextField
                style={{ width: "100%" }}
                onChange={(e) => changeFieldName(e, i)}
                required
                variant={enumCreator ? "standard" : "outlined"}
                size={enumCreator ? "small" : "medium"}
                label={"Name"}
                value={enumCreator ? field : field.name}
                error={enumCreator ? !field : !field.name}
                helperText={
                  (enumCreator ? !field : !field.name) && "Incorrect entry"
                }
              />
            </Grid>
            {!enumCreator && (
              <>
                <Grid item xs={6} md={3} alignContent={"center"}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Attribute
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={field.type}
                      onChange={(e) => changeFieldAttribute(e, i)}
                    >
                      {Object.keys(FormAttribute).map((key) => (
                        <MenuItem key={key} value={key as FormAttribute}>
                          {getFormAttributeName(key as FormAttribute)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} alignContent={"center"}>
                  <TextField
                    style={{ width: "100%" }}
                    label={"Description"}
                    onChange={(e) => changeFieldDescription(e, i)}
                    value={field.description}
                  />
                </Grid>
              </>
            )}

            <Grid
              {...removeFieldGridProps}
              sx={{ display: { xs: "none", md: "block" } }}
            >
              {removeFieldButton(i)}
            </Grid>
            {dropdownAccordion(field, i)}
            <Grid
              {...removeFieldGridProps}
              sx={{ display: { xs: "block", md: "none" } }}
            >
              {removeFieldButton(i)}
            </Grid>
          </>
        ))}
        <Grid item xs={12} md={6} alignContent={"center"}>
          <Button
            fullWidth={!enumCreator}
            variant="outlined"
            size={enumCreator ? "medium" : "large"}
            type="submit"
            color="success"
            onClick={addNewField}
          >
            {enumCreator ? "Add value" : "Add new field"}
          </Button>
        </Grid>
        {onSubmit && (
          <Tooltip arrow title={disableSubmitTooltip}>
            <Grid item xs={12} md={6} alignContent={"center"}>
              <Button
                disabled={disableSubmitButton}
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                onClick={onSubmit}
              >
                Submit form
              </Button>
            </Grid>
          </Tooltip>
        )}
      </Grid>
    </div>
  )
}
