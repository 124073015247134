import React, { useEffect, useState } from "react"
import { CustomForm } from "../../../features/customForm/customForm"
import { CustomFormDto } from "../../../shared/models/customFormDto"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { selectDemoForms } from "../../../redux/common.selectors"
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch"
import { getDemoForms } from "../../../redux/common.slice"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import Box from "@mui/material/Box"
import { Skeleton, Tab } from "@mui/material"
import { resetSpeakToFillResponse } from "../../../features/pressToSpeak/redux/voiceRecorder.slice"
import { Block } from "../../../features/block/Block"

export function Action() {
  const dispatch = useAppDispatch()
  const demoForms: CustomFormDto[] = useAppSelector(selectDemoForms)

  const [selectedTab, setSelectedTab] = useState<string | null>(null)

  useEffect(() => {
    dispatch(getDemoForms())
    dispatch(resetSpeakToFillResponse())
  }, [])

  useEffect(() => {
    if (demoForms.length > 0) {
      setSelectedTab(demoForms[0]._id)
    }
  }, [demoForms])

  const onTabChange = (event: React.SyntheticEvent, tabId: string) => {
    dispatch(resetSpeakToFillResponse())
    setSelectedTab(tabId)
  }

  return (
    <Block
      id={"examples"}
      title={
        <>
          See SpeakFill in action <RocketLaunchIcon sx={{ fontSize: 30 }} />
        </>
      }
    >
      {selectedTab ? (
        <TabContext value={selectedTab || ""}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              variant="scrollable"
              scrollButtons="auto"
              onChange={onTabChange}
              aria-label="lab API tabs example"
            >
              {demoForms.map((form) => (
                <Tab
                  className={"form-tab"}
                  key={form?._id + ""}
                  label={form.form_name}
                  value={form?._id + ""}
                />
              ))}
            </TabList>
          </Box>
          {demoForms.map((form) => (
            <TabPanel
              key={form?._id}
              value={form?._id + ""}
              sx={{ p: 0, pt: 3 }}
            >
              <CustomForm demoTooltip={true} form={form} />
            </TabPanel>
          ))}
        </TabContext>
      ) : (
        <TabContext value={"1"}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList aria-label="lab API tabs example">
              <Tab
                label={
                  <Skeleton variant="rectangular" width={210} height={24} />
                }
                value={"1"}
              />
              <Tab
                label={
                  <Skeleton variant="rectangular" width={210} height={24} />
                }
                value={"2"}
              />
            </TabList>
          </Box>
          <TabPanel key={"1"} value={"1"} sx={{ p: 0, pt: 3 }}>
            <Skeleton variant="rectangular" width="100%" height={300} />
          </TabPanel>
        </TabContext>
      )}
    </Block>
  )
}
