import React, { useEffect, useRef, useState } from "react"
import { Button, ButtonGroup, Fab } from "@mui/material"
import MicIcon from "@mui/icons-material/Mic"
import { selectIsRecording } from "./redux/voiceRecorder.selectors"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { sendSpeakFill, setRecording } from "./redux/voiceRecorder.slice"
import { DevMode } from "../devMode/devMode"
import Tooltip from "@mui/material/Tooltip"
import CustomTooltip from "../customTooltip/customTooltip"
import { showToast } from "../../redux/common.slice"
import ReactGA from "react-ga4"

interface IVoiceRecorder {
  formId: string | null
  demoTooltip?: boolean
  tooltipText?: string
}

const VoiceRecorder: React.FC<IVoiceRecorder> = ({
  formId,
  demoTooltip,
  tooltipText,
}) => {
  const dispatch = useAppDispatch()
  const recording = useAppSelector(selectIsRecording)
  const [audioURL, setAudioURL] = useState("")
  const mediaRecorder = useRef<MediaRecorder | null>(null)

  useEffect(() => {
    if (audioURL && !recording) {
      const fetchData = async () => {
        const blob = await fetchBlob()
        sendToAPI(blob)
      }
      fetchData()
    }
  }, [audioURL, recording])

  const handleStartRecording = async () => {
    ReactGA.event({
      category: "Button",
      action:
        "Start recording" +
        window.location.pathname +
        window.location.search +
        formId,
    })
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert("Your browser does not support the Media Devices API.")
      return
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      dispatch(setRecording(true))

      mediaRecorder.current = new MediaRecorder(stream)

      mediaRecorder.current.addEventListener("dataavailable", (event) => {
        const audioBlob = new Blob([event.data], { type: "audio/webm" })
        setAudioURL(URL.createObjectURL(audioBlob))
      })
      mediaRecorder.current.start()
    } catch (error) {
      console.error("Error:", error)
    }
  }

  const handleStopRecording = async () => {
    ReactGA.event({
      category: "Button",
      action:
        "Stop recording" +
        window.location.pathname +
        window.location.search +
        formId,
    })

    if (mediaRecorder.current) {
      mediaRecorder.current.stream.getTracks().forEach((track) => track.stop())
      mediaRecorder.current.stop()
      dispatch(setRecording(false))
    }
  }

  const fetchBlob = async () => {
    const response = await fetch(audioURL)
    const blob = await response.blob()
    return new File([blob], "audio.webm", { type: "audio/webm" })
  }

  const sendToAPI = (audioBlob: any) => {
    const formData = new FormData()
    formData.append("file", audioBlob, "recorded_audio.wav")
    if (formId != null) {
      dispatch(sendSpeakFill({ formId, formData, demo: true }))
    } else {
      dispatch(showToast({ message: "Form not initialized", variant: "error" }))
    }
  }

  return (
    <div
      style={{
        width: " 100%",
        display: "flex",
        alignItems: "start",
        justifyContent: "center",
      }}
    >
      <CustomTooltip text={tooltipText} visible={demoTooltip}>
        <Fab
          sx={{ zIndex: 90 }}
          onClick={recording ? handleStopRecording : handleStartRecording}
          variant={"extended"}
          color={recording ? "error" : "success"}
        >
          <MicIcon sx={{ fontSize: 40 }} />
          {recording ? "Recording" : "Press to SpeakFill"}
        </Fab>
      </CustomTooltip>
      <DevMode>
        <Tooltip title={!audioURL ? "No audio" : ""}>
          <ButtonGroup
            disabled={!audioURL}
            aria-label="outlined primary button group"
          >
            <Button
              variant="outlined"
              href={audioURL}
              download="recorded_audio.wav"
            >
              Download Recorded Audio
            </Button>

            <Button
              variant="contained"
              onClick={async () => sendToAPI(await fetchBlob())}
            >
              Send to API
            </Button>
          </ButtonGroup>
        </Tooltip>
      </DevMode>
    </div>
  )
}

export default VoiceRecorder
