import React from "react"
import { Container } from "@mui/material"
import Typography from "@mui/material/Typography"
import ReactMarkdown from "react-markdown"
import markdown from "./TermsConditions.md"

export default function TermsConditions() {
  return (
    <Container component="section" sx={{ mt: 1, mb: 1 }}>
      <Typography gutterBottom variant="h3" sx={{ mb: 5 }}>
        Terms and Conditions
      </Typography>
      <Typography component="div">
        <ReactMarkdown children={markdown}></ReactMarkdown>
      </Typography>
    </Container>
  )
}
