import React from "react"
import { Grid } from "@mui/material"
import Typography from "@mui/material/Typography"
import { Block } from "../../../features/block/Block"
import EmailIcon from "@mui/icons-material/Email"

export function ContactUs() {
  const sendEmail = () => {
    window.location.href =
      "mailto:example@email.com?subject=Subject&body=Body%20text"
  }

  return (
    <Block id={"contact-us"} title={"Talk to Us"}>
      <Grid
        className={"contact-us"}
        container
        spacing={5}
        sx={{ display: "flex" }}
      >
        <Grid item xs={12} md={6} alignContent={"left"}>
          <Typography className={"para-text"}>
            Whether you have questions about how to use SpeakFill or you want to
            know the possibilities for your use-case, hit us up and let’s make
            form-filling a breeze together!
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <EmailIcon fontSize="medium" />
          <Typography
            className={"para-text"}
            onClick={sendEmail}
            sx={{ ml: "5px" }}
          >
            support@speakfill.com
          </Typography>
        </Grid>
      </Grid>
    </Block>
  )
}
