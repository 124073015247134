import { FormValues } from "../../../shared/models/FormValues"

export type elementToLoad = "speekToFill"

export interface VoiceRecorderState {
  recording: boolean
  speakToFillResponse: FormValues | undefined
  elementsToLoad: elementToLoad[]
}

export const initialState: VoiceRecorderState = {
  recording: false,
  speakToFillResponse: undefined,
  elementsToLoad: [],
}
